import { css } from '@emotion/core';
import React from 'react';
import { mdUp } from '../styles/breakpoints';

const styles = css`
	background-color: #f8fcfd;
	height: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	padding: 30px;
	h2 {
		font-size: 24px;
		margin-bottom: 40px;
	}
	figure {
		min-height: 119px;
		margin-bottom: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	> :last-child {
		margin-bottom: 0;
	}
	@media ${mdUp} {
		padding: 50px 70px;
	}
`;

const ServiceItem = ({ children, title, image }) => (
	<div css={styles}>
		<h2>{title}</h2>
		<figure>
			{image}
		</figure>
		<p>
			{children}
		</p>
	</div>
);

export default ServiceItem;
