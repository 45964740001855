import React from 'react';
import Fade from '../components/Fade';
import IntroTitleBox from '../components/IntroTitleBox';
import Container from '../components/layout/Container';
import LinkItem from '../components/LinkItem';
import LinksList from '../components/LinksList';
import MainTitle from '../components/MainTitle';
import PerexText from '../components/PerexText';
import Meta from '../components/seo/Meta';
import SrOnlyTitle from '../components/seo/SrOnlyTitle';
import ServiceItem from '../components/ServiceItem';
import ServicesList from '../components/ServicesList';
import MainLayout from '../layouts/MainLayout';


const Index = () => (
	<MainLayout>
		<Meta
			title="Mé advokátní služby"
			description="Naše spolupráce může začít prověrkou business plánu, zpracováním jednoduché smlouvy nebo se můžu rovnou postarat o vše, co přináší vaše podnikání."
		/>

		<SrOnlyTitle>Mé advokátní služby</SrOnlyTitle>

		<IntroTitleBox>
			<Container>
				<MainTitle>
					Pomáhám rodinným firmám a&nbsp;startupům s&nbsp;právem
				</MainTitle>
			</Container>
		</IntroTitleBox>

		<Container>
			<Fade bottom>
				<PerexText>
					<p>
						<strong>Naše spolupráce může začít prověrkou business plánu, zpracováním jednoduché smlouvy nebo&nbsp;se můžu rovnou postarat o&nbsp;vše, co&nbsp;přináší vaše podnikání.</strong> Podívejte se, co&nbsp;vše můžeme společně posunout k&nbsp;lepšímu. Jaké jsou vaše potřeby?
					</p>
				</PerexText>
			</Fade>


			<ServicesList>
				<Fade left delay={100}>
					<ServiceItem
						title="Rodinné firmy / Podnikatelé"
						image={<img src="/images/illustration-rodinne-firmy@2x.png" width="140" height="101" alt="" />}
					>
						Aby vaše rodinné podnikání vzkvétalo a&nbsp;bylo o něj dobře postaráno i&nbsp;pro&nbsp;další generace.
					</ServiceItem>
				</Fade>

				<Fade right delay={100}>
					<ServiceItem
						title="Smlouvy o&nbsp;dílo"
						image={<img src="/images/illustration-smlouvy-o-dilo@2x.png" width="131" height="86" alt="" />}
					>
						Pro&nbsp;ochranu vaší dobře odvedené práce, ať&nbsp;už tvoříte cokoliv úžasného.
					</ServiceItem>
				</Fade>

				<Fade left delay={200}>
					<ServiceItem
						title="Autorskoprávní dokumentace"
						image={<img src="/images/illustration-autorskopravni-dokumentace@2x.png" width="132" height="104" alt="" />}
					>
						Pro&nbsp;ochranu vašeho autorství, které&nbsp;má největší hodnotu.
					</ServiceItem>
				</Fade>

				<Fade right delay={200}>
					<ServiceItem
						title="Obecná smluvní dokumentace"
						image={<img src="/images/illustration-obecna-smluvni-dokumentace@2x.png" width="130" height="111" alt="" />}
					>
						Dobré účty dělají dobré přátele. Dobré smlouvy zakládají dlouhodobou prospěšnou spolupráci.
					</ServiceItem>
				</Fade>

				<Fade left delay={300}>
					<ServiceItem
						title="Korporátní záležitosti"
						image={<img src="/images/illustration-korporatni-zalezitosti@2x.png" width="97" height="99" alt="" />}
					>
						Pro&nbsp;pořádek ve&nbsp;vašich interních právních dokumentech.
					</ServiceItem>
				</Fade>

				<Fade right delay={300}>
					<ServiceItem
						title="GDPR a&nbsp;EU"
						image={<img src="/images/illustration-gdpr-eu@2x.png" width="155" height="119" alt="" />}
					>
						Abyste měli zdravé vztahy se svými klienty i&nbsp;se samotnou EU.
					</ServiceItem>
				</Fade>

				<Fade left delay={400}>
					<ServiceItem
						title="Advokátní úschova"
						image={<img src="/images/illustration-advokatni-uschova@2x.png" width="113" height="113" alt="" />}
					>
						Pro&nbsp;důvěryhodnou rovnováhu mezi smluvními stranami.
					</ServiceItem>
				</Fade>

				<Fade right delay={400}>
					<ServiceItem
						title="Ostatní služby"
						image={<img src="/images/illustration-ostatni-sluzby@2x.png" width="166" height="112" alt="" />}
					>
						Pomohu vám třeba i&nbsp;s&nbsp;nákupem nemovitosti nebo jiným požadavkem.
					</ServiceItem>
				</Fade>
			</ServicesList>

			<Fade bottom>
				<h2 className="h3">Potřebujete vědět více?</h2>
			</Fade>
			<LinksList>
				<LinkItem to="/kdo-jsem/">Chcete mě poznat blíže? Přečtěte si krátké osobní interview.</LinkItem>
				{/* <LinkItem to="/blog/">Píšu o svých zkušenostech a důležitých věcech.</LinkItem> */}
				<LinkItem to="/kontakty/">Jak se můžeme spojit?</LinkItem>
			</LinksList>
		</Container>
	</MainLayout>
);

Index.propTypes = {};

export default Index;
