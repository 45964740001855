import { css } from '@emotion/core';
import React from 'react';
import { smUp } from '../styles/breakpoints';

const styles = css`
	margin-bottom: 50px;
	ul {
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 -20px -20px;
	}
	li {
		max-width: 100%;
		flex: 1 0 100%;
		padding: 0 0 20px 20px;
	}
	@media ${smUp} {
		li {
			max-width: 50%;
			flex-basis: 50%;
		}
	}
`;

const ServicesList = ({ children }) => (
	<div css={styles}>
		<ul>
			{React.Children.map(children, child => (
				<li>
					{child}
				</li>
			))}
		</ul>
	</div>
);

export default ServicesList;
