import React from 'react';
import { css } from '@emotion/core';

const styles = css`
	font-size: 18px;
	max-width: 700px;
	margin-bottom: 50px;
`;

const PerexText = ({ children }) => (
	<div css={styles}>
		{children}
	</div>
);

export default PerexText;
